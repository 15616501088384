<template>
  <div class="notice-container">
    <el-row>
      <el-col class="notice-left" :span="17">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div>公告</div>
            <div>></div>
            <div class="last-text">公告详情</div>
          </div>
          <div class="notice-title">【职业健康】一切为了员工健康，企业开展《职业病防治法》宣传周活动</div>
          <div class="notice-time">
            <div>发布时间： 2022/04/26 16:52:09</div>
            <div>浏览： 115次</div>
          </div>
          <p>
            为助力健康亚运建设，保障劳动者职业健康权益4月25日-5月1日是全国《职业病防治法》第20个宣传周，为深入贯彻《职业病防治法》，进一步提高员工对职业病危害性的认识，加强自我保护和法律意识，杭州里德通信有限公司开展《职业病防治法》宣传培训活动。
          </p>

          <p style="margin-top: 15px;">
            宣传培训中，首先通过视频为大家介绍职业病防治法基础知识，让大家了解什么是“职业病”以及如何在生产过程中预防常见职业病危害。
          </p>
          <div class="notice-img-1">
            <img src="./1.png" srcset="./1@2x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <!-- <img src="./2.png" srcset="./2@2x.png 2x" alt=""> -->
          </div>
          <p style="margin-top: 15px;">
            接下来《职业病防治法》宣传负责人为大家详细讲解：我国职业病有哪些（尘肺病及其他呼吸系统疾病、职业性放射性疾病、职业性耳鼻喉口腔疾病等）。 </p>
          <p style="margin-top: 15px;">
            结合公司产品加工特性及相关工艺流程，针对车间涉及的危害性因素，公司体系定期（每年/次）组织第三方机构为企业做符合要求的《工作场所职业病危害因素检测》，公司为相关岗位提供有效防护用品，且车间管理人员不定期监督检查员工的使用。 </p>
          <div class="notice-img-1">
            <img src="./2.png" srcset="./2@2x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <!-- <img src="./2.png" srcset="./2@2x.png 2x" alt=""> -->
          </div>
          <p style="margin-top: 15px;">
            为营造宣传氛围，培训完成现场对参训员工进行《职业病防治法》基础知识点的测试，来加深大家对《职业病防治法》宣传的认知。
          </p>
          <div class="notice-img-1">
            <img src="./3.png" srcset="./3@2x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <!-- <img src="./2.png" srcset="./2@2x.png 2x" alt=""> -->
          </div>
          <p style="margin-top: 15px;">
            对员工进行职业健康安全宣传教育不仅是为了保护员工，也是企业发展的必然趋势，将树立企业良好的社会形象和品质
          </p>
          <div class="notice-img-1">
            <img src="./4.png" srcset="./4@2x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <!-- <img src="./2.png" srcset="./2@2x.png 2x" alt=""> -->
          </div>
          <div class="notice-footer">
            <div class="notice-prev">
              <router-link tag="span" to="/notice13">
                < 上一篇</router-link>
            </div>
            <div class="notice-next">
              <router-link tag="span" to="/notice11">下一篇 ></router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col class="notice-right" :span="7">
        <div class="content">
          <div class="title">最新公告</div>
          <div>
            <div class="notice-other-1 pointer">
              <router-link tag="span" to="/notice14">童年，是一只载着无数快乐的小船；童年,……</router-link>
            </div>
            <div class="notice-other-time-1">2022-06-01 15:26</div>

            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice13">小鸟为天空而歌，蜜蜂为花朵而歌，今天……</router-link>
            </div>
            <div class="notice-other-time-2">2022-05-01 10:39</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice12">为助力健康亚运建设，保障劳动者职业健……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-26 16:52</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice11">2022年3月24日，杭州里德通信有限公司桐……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-25 11:26</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.notice-container {
  padding: 24px gap() 56px;
}

.notice-left {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 16px 32px 40px;
    font-size: 16px;
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-time {
  display: flex;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    margin-right: 32px;
  }
}

.notice-img-1,
.notice-img-2 {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.notice-img-3 {
  img {
    display: block;
    margin: auto;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 64px;
  border-top: 1px solid #d4d4d4;
}

.notice-prev {
  color: #da261d;
  cursor: pointer;
}

.notice-next {
  color: #da261d;
  cursor: pointer;
}

.notice-right {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 24px 24px 400px;
    box-sizing: border-box;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
}

.notice-other-time-1,
.notice-other-time-2 {
  padding: 8px 0;
  color: #a6a6a6;
  border-bottom: 1px solid #d4d4d4;
}

.notice-other-2 {
  margin-top: 32px;
}
</style>
